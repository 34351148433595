@import 'src/global/styles/palette';

.notifications-page--wrapper {
  width: 100%;

  .app-pages--content {
    .content--main {
      padding-right: 24px;
      width: 475px;
    }

    .content--additional {
      width: 270px;
    }
  }

  .notifications-page--link-back {
    color: $black;
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  .notifications-page--header {
    margin-bottom: 16px;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header {
      &--title {
        font-weight: 600;
        color: $gray-700;
      }

      &--control-btn {
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;

        &:hover {
          cursor: pointer;

          path {
            stroke: $primary-700;
          }
        }
      }
    }
  }
}
