@import 'src/global/styles/palette';

.network-user--wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 25px;
  }

  .user-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .user--avatar {
      margin-right: 15px;
    }

    .user--details {
      .user--name {
        a {
          font-size: 18px;
          line-height: 28px;
          color: $gray-700;
          font-weight: 500;
          text-decoration: none;
        }

        .verified-icon {
          margin-left: 10px;
        }
      }

      .user--position {
        font-size: 14px;
        line-height: 20px;
        color: $gray-500;
        font-weight: 500;
      }
    }
  }

  .user-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
    flex: 0 1 250px;
    width: 100%;
    max-width: 275px;

    .btn-secondary {
      width: 100px;
    }

    .btn-primary {
      width: 145px;
    }

    .btn-secondary-gray:last-child {
      width: 38px;
      height: 38px;
      padding: 5px;
    }

    .btn-secondary-gray:first-child {
      width: 140px;
    }
  }
}
