@import 'src/global/styles/palette';

.info-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(54, 63, 114, 0.9);
  z-index: 750;
  display: flex;
  justify-content: center;
  align-items: center;

  &.info-modal-backdrop-animation-enter {
    opacity: 0;
  }

  &.info-modal-backdrop-animation-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }

  &.info-modal-backdrop-animation-exit {
    opacity: 1;
  }

  &.info-modal-backdrop-animation-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }

  .info-modal {
    width: 100%;
    max-width: 400px;
    padding: 25px;
    border-radius: 12px;
    background-color: $white;

    &.info-modal-animation-enter {
      opacity: 0;
      transform: scale(0.3, 0.3);
    }

    &.info-modal-animation-enter-active {
      opacity: 1;
      transform: scale(1, 1);
      transition: opacity 300ms, transform 300ms;
    }

    &.info-modal-animation-exit {
      opacity: 1;
      transform: scale(1, 1);
    }

    &.info-modal-animation-exit-active {
      opacity: 0;
      transform: scale(0.3, 0.3);
      transition: opacity 300ms, transform 300ms;
    }

    .info-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .info-modal--icon {
        margin-bottom: 20px;
      }

      .info-modal--title {
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: $gray-900;
        text-align: center;
      }

      .info-modal--description {
        color: $gray-500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
      }
    }

    .info-modal--footer {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      .w-50 {
        flex: 0 1 50%;
        width: 100%;
        max-width: 50%;

        &:first-child {
          margin-right: 6px;
        }

        &:last-child {
          margin-left: 6px;
        }
      }
    }
  }
}
