@import 'src/global/styles/palette';

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

.spinner-box {
  background-color: $white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &.spinner-appearing-animation-enter {
    opacity: 0;
  }

  &.spinner-appearing-animation-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }

  &.spinner-appearing-animation-exit {
    opacity: 1;
  }

  &.spinner-appearing-animation-exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }

  .circle-border {
    width: 150px;
    height: 150px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: $primary-800;
    background: linear-gradient(0deg, rgba(83, 56, 158, 0.1) 25%, $primary-800 100%);
    animation: spin 0.8s linear 0s infinite;

    .circle-core {
      width: 100%;
      height: 100%;
      background-color: $white;
      border-radius: 50%;
    }
  }
}
