@import 'src/global/styles/palette';

.input-field--wrapper {
  width: 100%;
  margin-bottom: 15px;

  .input-label {
    width: 100%;
    display: block;
    margin-bottom: 5px;
    color: $gray-700;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }

  .input--wrapper {
    width: 100%;
    position: relative;

    .input {
      width: 100%;
      border: 1px solid $gray-300;
      border-radius: 8px;
      padding: 10px 15px;
      color: $gray-900;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      transition: 300ms ease-in;
      box-shadow: none;
      height: 40px;

      &:focus {
        border: 1px solid $primary-300;
        box-shadow: 0 0 0 4px $primary-100, 0 1px 2px 0 #1018280d;
      }

      &::placeholder,
      &:disabled {
        color: $gray-500;
      }

      &.state-danger {
        border: 1px solid $error-300;
        padding: 10px 35px 10px 15px;

        &:focus {
          box-shadow: 0 0 0 4px $error-100, 0 1px 2px 0 #1018280d;
        }
      }
    }

    .input-with-icon,
    .input-with-icon.state-danger {
      padding-left: 45px;
    }

    .input--controls-alerts {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
      display: flex;
      justify-content: center;
      align-items: center;

      .input--password-visibility-btn {
        background-color: transparent;
        border: none;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: 10px;
      }
    }

    .input--icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 15px;
    }
  }

  .input-field--hint {
    width: 100%;
    margin-top: 5px;
    color: $gray-500;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }

  .input-field--error-block-wrapper {
    width: 100%;

    &.error-block-animation-enter {
      opacity: 0;
    }

    &.error-block-animation-enter-active {
      opacity: 1;
      transition: opacity 500ms;
    }

    &.error-block-animation-exit {
      opacity: 1;
    }

    &.error-block-animation-exit-active {
      opacity: 0;
      transition: opacity 500ms;
    }

    .input-error-block {
      width: 100%;
      margin-top: 5px;

      .input-validation-message {
        color: $error-500;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
