@import 'src/global/styles/palette';

.faq-page--wrapper {
  width: 100%;

  .app-pages--content {
    .content--main {
      .page--header {
        width: 100%;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 135px;

        .header--title {
          font-size: 48px;
          line-height: 60px;
          color: $gray-700;
          font-weight: 600;
          margin-bottom: 10px;
        }

        .header--description {
          font-size: 16px;
          line-height: 20px;
          color: $gray-700;
          font-weight: 500;
          margin-bottom: 10px;
        }

        .btn {
          max-width: 200px;
        }
      }

      .page--body {
        width: 100%;
        margin-bottom: 50px;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;

        .body--title {
          font-size: 36px;
          line-height: 40px;
          color: $gray-700;
          font-weight: 600;
          margin-bottom: 15px;
        }

        .body--description {
          font-size: 18px;
          line-height: 28px;
          font-weight: 400;
          color: $gray-700;
        }
      }

      .page--accordions-wrapper {
        width: 100%;

        .MuiPaper-elevation {
          background-color: transparent;
          border: none;
          border-bottom: 1px solid #e8f0f8;
          border-radius: 0;
          box-shadow: none;

          &::before {
            display: none;
          }

          .MuiAccordionSummary-root {
            &.Mui-expanded {
              min-height: 45px;
            }

            .MuiAccordionSummary-content {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              position: relative;

              &.Mui-expanded {
                margin: 0;
              }

              .accordion-number {
                font-size: 30px;
                line-height: 38px;
                color: $primary-700;
                font-weight: 600;
                display: block;
                margin-right: 25px;
              }

              .accordion--title {
                font-size: 24px;
                line-height: 32px;
                color: $gray-700;
                font-weight: 500;
              }

              .accordion-control-btn {
                border: none;
                outline: none;
                width: 25px;
                height: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                background-color: transparent;
                cursor: pointer;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
              }
            }
          }

          .MuiCollapse-root {
            .MuiCollapse-wrapper {
              .MuiCollapse-wrapperInner {
                .MuiAccordion-region {
                  .MuiAccordionDetails-root {
                    padding: 15px 10px 15px 75px;
                    font-size: 16px;
                    line-height: 24px;
                    color: $gray-600;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
