@import 'src/global/styles/palette';

.view-selector {
  padding: 15px 25px;
  font-size: 18px;
  line-height: 28px;
  border: 1px solid $gray-200;
  border-radius: 8px;
  color: $gray-700;
  background-color: $white;
  min-width: 175px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .view-selector--icon {
    margin-right: 10px;
    stroke: $gray-700;
  }

  &.active {
    color: $white;
    border: 1px solid $primary-700;
    background-color: $primary-700;

    .view-selector--icon {
      stroke: $white;
    }
  }
}
