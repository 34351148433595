.add-social-page--content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  & .content--add-social-form-container {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}
