@import 'src/global/styles/palette';

.profile-details--wrapper {
  width: 100%;
  background-color: $white;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  padding: 25px;
  border-radius: 8px;

  .avatar--wrapper {
    margin-bottom: 15px;
  }

  .profile--name-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    .profile--name {
      font-size: 20px;
      line-height: 30px;
      color: $gray-900;
      font-weight: 600;
    }

    .profile-verified-icon {
      margin-left: 10px;
    }
  }

  .profile--location-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;

    .avatar--wrapper {
      margin-bottom: 0;
    }

    .location--name {
      font-size: 14px;
      line-height: 20px;
      color: $black;
      display: block;
      margin-left: 10px;
    }
  }

  .profile--industries-wrapper {
    width: 100%;
    max-width: 250px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 5px;
    gap: 7px;

    .industry--wrapper {
      padding: 2px 10px;
      background-color: $gray-100;
      margin-bottom: 10px;
      border-radius: 4px;

      .industry-name {
        font-size: 12px;
        line-height: 16px;
        color: $gray-700;
        font-weight: 500;
      }
    }
  }

  .profile--bio {
    width: 100%;
    margin-bottom: 15px;

    .text-editor--wrapper {
      .text-editor--content {
        border: none;
        padding: 0;
        display: flex;
        flex-flow: column;
        align-items: center;

        .text-editor {
          width: fit-content;
          display: flex;
          flex-flow: column;
          align-items: center;
          font-size: 12px;
          line-height: 16px;
          color: $gray-500;
          min-height: unset !important;

          ul,
          ol {
            padding-left: 15px;
          }
        }
      }
    }
  }

  .profile--controls-wrapper {
    width: 100%;

    .my-profile--controls {
      width: 100%;

      .controls-company {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .btn {
          width: 160px;

          &:first-child {
            margin-right: 8px;
          }

          &:last-child {
            margin-left: 8px;
          }
        }
      }

      .controls-influencer {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .btn {
          width: 160px;

          &:first-child {
            margin-right: 16px;
          }
        }
      }
    }

    .user-profile--controls {
      width: 100%;

      .influencer-view-controls {
        width: 100%;

        .controls-company {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .btn {
            width: 160px;

            &:first-child {
              margin-right: 8px;
            }

            &:last-child {
              margin-left: 8px;
            }
          }
        }

        .controls-influencer {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .btn {
            &:first-child {
              width: 160px;
              margin-right: 8px;
            }

            &:nth-child(2) {
              margin-right: 8px;
            }

            &:nth-child(2),
            &:last-child {
              width: 145px;
            }
          }
        }
      }

      .company-view-controls {
        width: 100%;

        .controls-company {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .btn {
            &:first-child {
              margin-right: 8px;
            }
          }
        }

        .controls-influencer {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .btn {
            &.btn--message {
              background-color: $primary-700 !important;
              color: $white !important;
            }

            &:first-child {
              width: 115px;
              margin-right: 8px;
            }

            &:nth-child(2) {
              width: 130px;
              margin-right: 8px;
            }

            &:nth-child(3) {
              width: 80px;
              margin-right: 8px;
            }

            &:nth-child(4) {
              margin-right: 8px;

              .btn--icon {
                margin-right: 0;
              }
            }

            &:nth-child(4),
            &:last-child {
              width: 38px;
              height: 38px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0;
            }

            &.active {
              &.saved {
                svg {
                  path {
                    stroke: $white;
                  }
                }
              }

              &.comparison {
                svg {
                  path {
                    fill: $white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.influencer-view {
    align-items: flex-start;

    .profile--industries-wrapper {
      justify-content: flex-start;
    }

    .profile--bio {
      .text-editor--wrapper {
        .text-editor--content {
          align-items: flex-start;
        }
      }
    }
  }
}
