@import 'src/global/styles/palette';

.log-in-form {
  width: 100%;
  max-width: 360px;

  .btn {
    &:not(:last-child) {
      margin-bottom: 15px;
    }

    .btn--icon {
      margin-right: 10px;
    }
  }

  .log-in-form--footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
  }

  .log-in-form--already-have {
    color: $gray-500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin: 30px 0;

    .link {
      margin-left: 5px;
    }
  }
}
