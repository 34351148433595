@import 'src/global/styles/palette';

.select-field--wrapper {
  width: 100%;
  margin-bottom: 15px;

  .select--label {
    width: 100%;
    display: block;
    margin-bottom: 5px;
    color: $gray-700;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }

  .select--wrapper {
    width: 100%;
    position: relative;

    .select--selected-values {
      display: flex;
      justify-content: flex-start;

      &.with-values {
        margin-bottom: 30px;
      }

      .selected--value {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 10px;
        background-color: $gray-100;
        margin-right: 10px;

        .value--text {
          font-size: 12px;
          line-height: 16px;
          color: $gray-700;
          font-weight: 500;
          margin-right: 5px;
        }

        .value--control-btn {
          background-color: transparent;
          border: none;
          outline: none;
          cursor: pointer;
        }
      }
    }

    .select {
      .select__control {
        min-height: 46px;
        border: 1px solid $gray-300;
        border-radius: 8px;

        &.select__control--is-focused {
          border: 1px solid $primary-300;
          box-shadow: 0 1px 2px 0 #1018280d;

          &.select__control--menu-is-open {
            box-shadow: 0 0 0 4px $primary-100, 0 1px 2px 0 #1018280d;
          }
        }

        .select__value-container {
          .select__placeholder {
            color: $gray-500;
          }

          .select__single-value {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .option--icon-wrapper {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 10px;
            }

            .option--text {
              color: $gray-700;
            }
          }
        }

        .select__indicators {
          .select__indicator-separator {
            display: none;
          }
        }
      }

      .select__menu {
        border: none;
        box-shadow: 0 4px 6px -2px #1018280d, 0 12px 16px -4px #1018281a;
        border-radius: 8px;

        .select__menu-list {
          .select__option {
            padding: 10px 15px;
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .option--icon-wrapper {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 10px;
            }

            .option--img-wrapper {
              margin-right: 10px;
            }

            .option--text {
              color: $gray-900;
              font-size: 16px;
              line-height: 24px;
            }

            &.select__option--is-focused,
            &:hover {
              background-color: $primary-50;

              .option--text {
                color: $primary-700;
              }
            }

            &.select__option--is-selected {
              background-color: $primary-700;

              .option--text {
                color: $primary-50;
              }
            }
          }
        }
      }

      &.select-outside {
        .select__control {
          .select__value-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .search--icon {
              margin-right: 10px;
            }

            .select__multi-value {
              display: none;
            }
          }

          .select__indicators {
            .select__clear-indicator {
              display: none;
            }
          }
        }
      }

      &.select-lg {
        .select__value-container {
          .select__placeholder {
            font-size: 16px;
            line-height: 24px;
          }

          .select__single-value {
            .option--text {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }

        .select__menu {
          .select__menu-list {
            .select__option {
              .option--text {
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
        }
      }

      &.select-md {
        .select__value-container {
          .select__placeholder {
            font-size: 14px;
            line-height: 20px;
          }

          .select__single-value {
            .option--text {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }

        .select__menu {
          .select__menu-list {
            .select__option {
              .option--text {
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }

  .select-field--hint {
    width: 100%;
    margin-top: 5px;
    color: $gray-500;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }

  .select-field--error-block-wrapper {
    width: 100%;

    &.error-block-animation-enter {
      opacity: 0;
    }

    &.error-block-animation-enter-active {
      opacity: 1;
      transition: opacity 500ms;
    }

    &.error-block-animation-exit {
      opacity: 1;
    }

    &.error-block-animation-exit-active {
      opacity: 0;
      transition: opacity 500ms;
    }

    .select-error-block {
      width: 100%;
      margin-top: 5px;

      .select-validation-message {
        color: $error-500;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
