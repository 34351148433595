@import 'src/global/styles/palette';

.compare-page--wrapper {
  width: 100%;

  .app-pages--content {
    .content--main {
      width: 100%;
      padding-right: 25px;

      .compare-page--header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        background-color: $white;
        padding: 19px 24px;

        .header--info {
          .title {
            color: $gray-900;
            font-size: 18px;
            font-weight: 500;
            line-height: 28px;
            display: flex;
            align-items: center;

            span {
              padding: 2px 8px;
              background-color: $primary-50;
              border-radius: 16px;
              margin-left: 8px;
              color: $primary-800;
              font-size: 12px;
              font-weight: 500;
              line-height: 16px;
            }
          }

          .description {
            margin-top: 4px;
            color: $gray-500;
            font-size: 14px;
          }
        }

        .header--controls {
          display: flex;
          gap: 12px;

          .btn {
            white-space: nowrap;
            display: flex;
            align-items: center;
            gap: 8px;
          }
        }
      }

      .compare-page--table {
        overflow-y: auto;

        .table {
          table-layout: fixed;
          width: auto;
          border-spacing: 0 8px;

          &--header-cell {
            min-width: 110px;

            &__with-tooltip {
              justify-content: center;
            }
          }

          &--cell {
            width: 175px;
            text-align: center;
          }

          &--row:not(:first-of-type) .table--cell {
            border-left: 1px solid $blue-gray-200;
          }

          &--row:nth-child(1):first-child {
            background-color: transparent;
          }

          &--row:nth-child(2n) {
            background-color: $primary-50;
          }

          &--row:nth-child(2n + 1) {
            background-color: $white;
          }
        }

        .info--name {
          width: auto;
        }
      }

      .compare-page--404 {
        width: 320px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .compare-page--404__icon-wrapper {
          margin-bottom: 16px;
          background-color: $primary-100;
          padding: 12px;
          border-radius: 100px;
          width: 48px;
          height: 48px;

          svg path {
            fill: $primary-600;
          }
        }

        .compare-page--404__title {
          margin-bottom: 4px;
          color: $gray-900;
          font-size: 18px;
          font-weight: 500;
          line-height: 28px;
        }

        .compare-page--404__description {
          color: $gray-500;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;

          &:last-of-type {
            margin-bottom: 24px;
          }
        }

        .btn {
          flex-shrink: 0;
        }
      }
    }
  }
}
