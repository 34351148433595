@import 'src/global/styles/palette';

.search-filters-wrapper {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  background-color: rgba(54, 63, 114, 0.9);

  &.backdrop-animation-enter {
    opacity: 0;
  }

  &.backdrop-animation-enter-active {
    opacity: 1;
    transition: opacity 400ms;
  }

  &.backdrop-animation-exit {
    opacity: 1;
  }

  &.backdrop-animation-exit-active {
    opacity: 0;
    transition: opacity 400ms;
  }

  .filters-container-wrapper {
    width: 100%;
    max-width: 400px;
    height: 100%;
    overflow: auto;
    padding: 30px 25px;
    background-color: $white;
    position: absolute;
    right: 0;
    top: 0;

    &.filter-container-animation-enter {
      opacity: 0;
      transform: translateX(100%);
    }

    &.filter-container-animation-enter-active {
      opacity: 1;
      transform: translateX(0);
      transition: opacity 400ms, transform 400ms;
    }

    &.filter-container-animation-exit {
      opacity: 1;
    }

    &.filter-container-animation-exit-active {
      opacity: 0;
      transform: translateX(100%);
      transition: opacity 400ms, transform 400ms;
    }

    .filters-container--header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px;

      .header--title {
        font-size: 18px;
        line-height: 28px;
        color: $gray-700;
        font-weight: 600;
      }

      .header--close-btn {
        background-color: transparent;
        border: none;
        cursor: pointer;

        .close-btn--icon {
          width: 12px;
          height: 12px;
        }
      }
    }

    .filters-container--form {
      .range-wrapper {
        width: 100%;
        margin-bottom: 15px;

        .range-label {
          display: block;
          font-size: 14px;
          line-height: 20px;
          color: $gray-700;
          font-weight: 500;
          margin-bottom: 5px;
        }

        .range-inputs--wrapper {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .range-input-wrapper {
            flex: 0 1 50%;
            width: 100%;
            max-width: 50%;

            .input-field--wrapper {
              margin-bottom: 0;
            }

            &:first-child {
              padding-right: 8px;
            }

            &:last-child {
              padding-left: 8px;
            }
          }
        }

        .slider-wrapper {
          display: flex;
          justify-content: center;
          margin-top: 10px;

          .MuiBox-root {
            .MuiSlider-root {
              color: $primary-600;
              height: 8px;

              .MuiSlider-rail {
                background-color: $gray-200;
                opacity: 1;
                border-radius: 4px;
              }

              .MuiSlider-thumb {
                box-shadow: 0 2px 4px -2px rgba(16, 24, 40, 0.06),
                  0 4px 8px -2px rgba(16, 24, 40, 0.1);
                background-color: $white;
              }
            }
          }
        }
      }

      .filter-accordion {
        width: 100%;
        border-top: 1px solid #e8f0f8;
        border-bottom: 1px solid #e8f0f8;

        .accordion--header {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          padding: 25px 0;

          .header--title {
            font-size: 18px;
            line-height: 28px;
            color: $gray-700;
            font-weight: 600;
          }
        }

        .accordion--body {
          max-height: 0;
          overflow: hidden;
          transition: max-height 500ms;

          .checkboxes-wrapper {
            margin-bottom: 25px;

            .checkboxes-wrapper--label {
              display: block;
              font-size: 14px;
              line-height: 20px;
              color: $gray-700;
              font-weight: 500;
              margin-bottom: 20px;
            }
          }

          .select__menu {
            position: unset;
          }
        }

        &.collapsed {
          .accordion--header {
            .header--icon {
              transform: rotate(180deg);
            }
          }

          .accordion--body {
            max-height: 650px;
          }
        }
      }

      .verified-profile-check--wrapper {
        width: 100%;
        padding: 25px 0;
        border-bottom: 1px solid #e8f0f8;
      }

      .search-filters--footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 25px;

        .btn-link {
          color: $gray-700;
          max-width: 135px;
          padding: 8px 0;
        }

        .btn-primary {
          max-width: 120px;
        }
      }
    }
  }
}
