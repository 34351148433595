@import 'src/global/styles/palette';

.profile-settings-page--form {
  .linked-account--row {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    .row--status {
      flex: 0 1 calc(100% - 45px);
      width: 100%;
      max-width: calc(100% - 45px);
      margin-right: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 0;
      border: 1px solid $gray-300;
      background-color: $white;
      border-radius: 8px;

      .social-icon {
        margin-right: 15px;
      }

      .social-name {
        font-size: 16px;
        line-height: 20px;
        color: $gray-700;
        font-weight: 500;
      }
    }

    .row-control-btn {
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background-color: $primary-700;
      border: 1px solid $primary-700;
      cursor: pointer;
      text-decoration: none;

      &__success {
        background-color: $success-500;
        border: 1px solid $success-500;
        cursor: default;

        svg > * {
          stroke: $white;
        }
      }
    }
  }
}
