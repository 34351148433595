@import 'src/global/styles/palette';

.search-sub-header {
  width: 100%;
  padding: 20px 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: $white;
  border-bottom: 1px solid $gray-200;

  .header--title {
    font-size: 18px;
    line-height: 28px;
    color: $gray-900;
    font-weight: 500;
    margin-right: 15px;
  }

  .header--value {
    display: block;
    color: $primary-700;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    padding: 2px 8px;
    background-color: $primary-50;
    border-radius: 16px;
  }
}
