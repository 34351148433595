@import 'src/global/styles/palette';

.social-container--wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  min-height: 100vh;
  height: 100%;

  .content-container,
  .info-container {
    flex: 0 1 50%;
    max-width: 50%;
    width: 100%;

    &.content-container {
      display: flex;
      flex-flow: column;
    }

    &.info-container {
      background-position: center;
      background-size: cover;
    }
  }
}
