@import 'src/global/styles/palette';

.not-found-page--wrapper {
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .page--content-text,
  .page--image {
    flex: 0 1 50%;
    width: 100%;
    max-width: 50%;

    &.page--content-text {
      margin-right: 25px;

      .content--type {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: $primary-700;
        margin-bottom: 10px;
      }

      .content--title {
        font-size: 48px;
        line-height: 60px;
        color: $gray-700;
        font-weight: 700;
        margin-bottom: 25px;
      }

      .content--description {
        font-size: 18px;
        line-height: 28px;
        color: $gray-500;
        font-weight: 500;
        margin-bottom: 50px;
      }

      .content--controls {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .btn {
          width: 200px;

          &:first-child {
            margin-right: 15px;
          }
        }
      }
    }

    &.page--image {
      margin-left: 25px;

      .image--not-found {
        width: 100%;
      }
    }
  }
}
