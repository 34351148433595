.additional-page--wrapper {
  .additional-page--form {
    .form--view-selector {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
    }
  }
}
