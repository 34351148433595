@import 'src/global/styles/palette';

.company-search-page--wrapper {
  width: 100%;

  .company-search-page--filters-title {
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 15px;
    padding: 0 25px;
  }

  .company-search-page--filters-wrapper {
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    padding: 0 25px;

    .filters-form {
      display: flex;
      justify-content: flex-start;
      flex: 0 1 calc(100% - 200px);
      width: 100%;
      max-width: calc(100% - 200px);

      .search-input {
        flex: 0 1 100%;
        width: 100%;
        max-width: 100%;
        padding: 0 15px 0 0;

        .input-field--wrapper {
          margin-bottom: 0;

          .input {
            padding-top: 7px;
            padding-bottom: 7px;
          }
        }
      }
    }

    .btn {
      flex: 0 1 200px;
      width: 100%;
      max-width: 200px;
    }
  }

  .company-search-page--active-filters-wrapper {
    width: 100%;
    margin-bottom: 65px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 25px;

    .active-filter--item {
      padding: 2px 8px;
      background-color: $gray-100;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;

      .item--name {
        font-size: 12px;
        line-height: 16px;
        color: $gray-700;
        font-weight: 500;
        display: block;
        margin-right: 5px;
        white-space: nowrap;
      }
    }
  }

  .company-search-result--wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    padding-bottom: 16px;
  }
}
