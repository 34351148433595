@import 'src/global/styles/palette';

.tooltip-wrapper {
  display: inline-flex;
  position: relative;

  .tooltip-btn {
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    width: 16px;
    height: 16px;
  }

  .tooltip-text {
    padding: 8px 12px;
    background-color: $white;
    border-radius: 8px;
    width: 160px;
    max-width: 160px;
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);

    &.tooltip--animation-enter {
      opacity: 0;
    }

    &.tooltip--animation-enter-active {
      opacity: 1;
      transition: opacity 300ms;
    }

    &.tooltip--animation-exit {
      opacity: 1;
    }

    &.tooltip--animation-exit-active {
      opacity: 0;
      transition: opacity 300ms;
    }
  }
}
