@import '../../../global/styles/palette';

.chat-item {
  display: flex;
  gap: 8px;
  width: 100%;
  max-width: 100%;
  cursor: pointer;

  &--message-wrapper {
    display: flex;
    flex-direction: column;
  }

  &--user-name {
    display: flex;
    gap: 8px;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.43;
    color: $gray-700;
  }

  &--message {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.33;
    color: $gray-600;
  }

  &--message-count {
    margin-top: 20px;
    margin-bottom: auto;
    margin-left: auto;
    background: $gray-100;
    border-radius: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.43;
    color: $gray-700;
    padding: 2px 10px;
  }
}
