@import 'src/global/styles/palette';

.anchor-navigation--wrapper {
  width: 100%;
  padding: 15px;
  background-color: $white;
  border-radius: 8px;

  .anchor--route {
    color: $gray-700;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding: 10px;
    display: block;
    text-decoration: none;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &.active-anchor {
      padding-left: 25px;

      &::before {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: block;
        background-color: $success-500;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
  }

  .anchor-navigation--footer {
    width: 100%;
    border-top: 1px solid $gray-100;
    padding-top: 15px;

    .upgrade-btn {
      color: $primary-700;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      text-decoration: none;
      width: 100%;
      text-align: center;
      display: block;
    }
  }
}
