@import 'src/global/styles/palette';

.profile-feed--wrapper {
  width: 100%;

  .profile-feed--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 15px;

    h2 {
      color: $gray-700;
      font-size: 24px;
      line-height: 32px;
      font-weight: 600;
    }

    .profile-feed--header-select {
      .sort-select {
        &__control {
          background-color: transparent;
          border: 0;

          &--is-focused {
            box-shadow: none;
          }
        }

        &__indicator-separator {
          display: none;
        }

        &__option {
          cursor: pointer;

          &:not(:last-child) {
            margin-bottom: 3px;
          }

          &--is-focused {
            background-color: transparent;
          }

          &--is-selected {
            background-color: $primary-700;
          }
        }
      }
    }
  }

  .post-editor {
    margin-bottom: 35px;
  }

  .loader-box {
    width: 100%;
    margin-left: 0;
    height: auto;
    padding: 50px 0;
  }
}
