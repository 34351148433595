@import 'src/global/styles/palette';

.feed-page--wrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  .feed-page--inner {
    width: 100%;
    max-width: 1200px;
  }

  .app-pages--content {
    .content--main {
      .feed-page--posts-wrapper {
        border-radius: 8px;

        &:not(:last-child) {
          margin-bottom: 25px;
        }
      }

      .feed-page--header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;

        h2 {
          color: $gray-700;
          font-size: 24px;
          line-height: 32px;
          font-weight: 600;
        }

        .feed-page--header-select {
          .sort-select {
            &__control {
              background-color: transparent;
              border: 0;

              &--is-focused {
                box-shadow: none;
              }
            }

            &__indicator-separator {
              display: none;
            }

            &__option {
              cursor: pointer;

              &:not(:last-child) {
                margin-bottom: 3px;
              }

              &--is-focused {
                background-color: $primary-700;
                color: $white;
              }

              &--is-selected {
                background-color: $primary-700;
              }
            }
          }
        }
      }
    }

    .content--additional {
      .additional--container {
        width: 100%;
        padding: 15px;
        background-color: $white;
        border-radius: 8px;

        &:not(:last-child) {
          margin-bottom: 25px;
        }

        .container--title {
          margin-bottom: 20px;
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          color: $gray-700;
        }

        .container--body {
          width: 100%;

          .user--row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            .user-info--wrapper {
              flex: 0 1 calc(100% - 80px);
              width: 100%;
              max-width: calc(100% - 80px);

              .info--name {
                max-width: 90px;
              }

              .info--subtitle {
                max-width: 90px;
              }
            }

            .btn {
              width: 70px;
            }
          }
        }

        .container--footer {
          padding: 15px 0 0;
          border-top: 1px solid #e8f0f8;
        }
      }
    }
  }
}
