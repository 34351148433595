@import 'src/global/styles/palette';

.feedback-stats {
  &--wrapper {
    width: 270px;
    background-color: $white;
    margin-bottom: 24px;
    padding: 24px 16px;
    border-radius: 8px;
    border: 1px solid $gray-100;
  }

  &--overall-rating {
    display: flex;
    align-items: center;
    gap: 5px;

    p {
      color: $gray-700;
      font-size: 18px;
      font-weight: 600;
    }
  }

  &--categories {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 18px;

    p {
      color: $gray-700;
      font-weight: 500;
      font-size: 14px;
      width: 25px;
    }
  }

  &--rating {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
