@import 'src/global/styles/palette';

.company-card--wrapper {
  flex: 0 1 25%;
  width: 100%;
  padding: 0 8px;
  margin: 16px 0 0;
  min-width: 225px;

  &:first-child {
    padding: 0 8px 0 0;
  }

  &:nth-child(4n) {
    padding: 0 0 0 8px;
  }

  .card-content--wrapper {
    width: 100%;
    height: 100%;
    background-color: $white;
    padding: 25px 20px;
    border-radius: 8px;
    border: 1px solid $gray-100;
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    .card-content--info {
      width: 100%;
      display: flex;
      flex-flow: column;
      align-items: center;
      margin-bottom: 15px;

      .card--avatar {
        margin-bottom: 15px;
      }

      .info--name-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .name {
          font-size: 18px;
          line-height: 28px;
          color: $gray-900;
          font-weight: 500;
          text-decoration: none;
          cursor: pointer;
        }

        .name--verified-icon {
          margin-left: 10px;
        }
      }

      .info--position {
        font-size: 16px;
        line-height: 24px;
        color: $primary-700;
        margin-bottom: 15px;
        min-height: 25px;
      }
    }

    .card-content--controls {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 20px;

      .actions-btn {
        margin-left: 15px;
      }
    }
  }
}
