@import 'src/global/styles/palette';

.auth-container--wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  min-height: 100vh;
  height: 100%;

  .content-container,
  .info-container {
    flex: 0 1 50%;
    max-width: 50%;
    width: 100%;

    &.content-container {
      display: flex;
      flex-flow: column;
    }

    &.info-container {
      background: linear-gradient(45deg, #101828 0%, #475467 100%);
      padding: 10% 95px 0;

      .info-container--content-wrapper {
        position: relative;

        .content-wrapper--icon {
          margin-bottom: 30px;
        }

        .content-wrapper--title {
          color: $white;
          font-weight: 500;
          font-size: 60px;
          line-height: 72px;
        }

        .content-wrapper--description {
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
          color: $white;
          margin-bottom: 30px;
        }

        .content-wrapper--people {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .people--text {
            font-size: 16px;
            line-height: 20px;
            color: $gray-200;
            font-weight: 500;
            margin-left: 15px;
          }
        }

        .content-wrapper--big-arrow-icon {
          position: absolute;
          bottom: -140px;
          left: -35%;
        }
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .auth-container--wrapper {
    .info-container {
      display: none;
    }

    .content-container {
      flex: 0 1 100%;
      max-width: 100%;
      width: 100%;
    }
  }
}
