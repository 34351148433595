@import 'src/global/styles/palette';

.network-page--wrapper {
  width: 100%;

  .app-pages--content {
    .content--main {
      .content--header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.m-15 {
          margin-bottom: 15px;
        }

        &.m-35 {
          margin-bottom: 35px;
        }

        .header--title {
          font-size: 18px;
          line-height: 28px;
          color: $gray-700;
          font-weight: 600;
        }

        .btn-link {
          font-size: 14px;
          line-height: 20px;
          color: $gray-700;
          width: 90px;

          .btn--icon {
            transform: rotate(180deg);
            margin: 0 0 0 10px;
          }
        }
      }

      .content--body {
        width: 100%;
        margin-bottom: 35px;

        .friends--empty-state {
          width: 100%;
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;
          margin-top: 50px;

          .avatar-icon {
            margin-bottom: 15px;
          }

          .empty-state--title {
            text-align: center;
            font-size: 18px;
            line-height: 28px;
            font-weight: 500;
            max-width: 350px;
            margin-bottom: 5px;
            color: $gray-900;
          }

          .empty-state--description {
            text-align: center;
            max-width: 350px;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            color: $gray-500;
            margin-bottom: 25px;
          }

          .btn-primary {
            max-width: 350px;
          }
        }
      }

      .form--wrapper {
        width: 100%;
        margin-bottom: 30px;

        .form {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: stretch;
          gap: 15px;

          .search-input {
            width: 100%;

            .input-field--wrapper {
              margin-bottom: 0;
            }
          }

          .btn {
            width: 200px;
            max-height: 50px;
          }
        }
      }
    }

    .content--additional {
      .page--navigation {
        width: 100%;
        padding: 15px;
        background-color: $white;
        border: 1px solid $gray-100;
        border-radius: 8px;
        margin-bottom: 25px;

        .navigation--body {
          width: 100%;

          .navigation--link {
            font-size: 14px;
            line-height: 20px;
            color: $gray-700;
            font-weight: 500;
            text-decoration: none;
            position: relative;
            padding: 10px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            &:not(:last-child) {
              margin-bottom: 8px;
            }

            &.active {
              padding-left: 25px;

              &::before {
                content: '';
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: $success-500;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
              }
            }

            .link--counter {
              padding: 2px 10px;
              display: block;
              background-color: $gray-100;
              font-size: 14px;
              line-height: 20px;
              font-weight: 500;
              border-radius: 16px;
            }
          }
        }

        .navigation--footer {
          padding-top: 8px;
          border-top: 1px solid #e8f0f8;
        }
      }

      .page--settings {
        width: 100%;
        padding: 15px;
        background-color: $white;
        border: 1px solid $gray-100;
        border-radius: 8px;
        margin-bottom: 25px;

        .setting--description {
          text-align: center;
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          color: $gray-700;
          margin-bottom: 15px;
        }

        .btn {
          border: none;
        }
      }

      .page--people {
        width: 100%;
        padding: 15px;
        background-color: $white;
        border: 1px solid $gray-100;
        border-radius: 8px;
        margin-bottom: 25px;

        .people--title {
          width: 100%;
          font-size: 14px;
          line-height: 20px;
          color: $gray-700;
          font-weight: 500;
          margin-bottom: 15px;
        }

        .people--body {
          width: 100%;

          .people--row {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;

            .user-info--wrapper {
              flex: 0 1 75%;
              width: 100%;
              max-width: 75%;

              .info--name {
                max-width: 110px;
              }

              .info--subtitle {
                max-width: 110px;
              }
            }

            .btn {
              width: 54px;
            }
          }
        }

        .people--footer {
          padding-top: 8px;
          border-top: 1px solid #e8f0f8;
        }
      }
    }
  }
}
