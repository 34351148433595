@import 'src/global/styles/palette';

.saved-list-page--wrapper {
  width: 100%;

  .app-pages--content {
    width: 100%;

    .content--main {
      flex: 0 1 100%;
      width: 100%;
      padding-right: 25px;

      .saved-list-page--header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        background-color: $white;
        padding: 19px 24px;

        .header--title {
          .title {
            color: $gray-900;
            font-size: 18px;
            font-weight: 500;
            line-height: 28px;
            display: flex;
            align-items: center;

            span {
              padding: 2px 8px;
              background-color: $primary-50;
              border-radius: 16px;
              margin-left: 8px;
              color: $primary-800;
              font-size: 12px;
              font-weight: 500;
              line-height: 16px;
            }
          }

          .description {
            margin-top: 4px;
            color: $gray-500;
            font-size: 14px;
          }
        }

        .header--controls {
          display: flex;
          gap: 12px;

          button {
            white-space: nowrap;
          }
        }
      }
    }
  }

  .saved-page--404 {
    width: 320px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .saved-page--404__icon-wrapper {
      margin-bottom: 16px;
      background-color: $primary-100;
      padding: 12px;
      border-radius: 100px;
      width: 48px;
      height: 48px;
    }

    .saved-page--404__title {
      margin-bottom: 4px;
      color: $gray-900;
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
    }

    .saved-page--404__description {
      color: $gray-500;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;

      &:last-of-type {
        margin-bottom: 24px;
      }
    }

    .btn {
      flex-shrink: 0;
    }
  }
}
