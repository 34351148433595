@import 'src/global/styles/palette';

.profile-feedbacks {
  &--wrapper {
    width: 100%;
    background-color: $white;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid $gray-100;

    .header--user {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .user--avatar {
        margin-right: 10px;
      }

      .user--info {
        .info--name {
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          color: $gray-700;

          .verified-icon {
            margin-left: 10px;
          }
        }

        .info--email {
          font-size: 14px;
          line-height: 20px;
          color: $gray-500;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 160px;
          white-space: nowrap;
          display: block;
        }
      }

      .user--info-name {
        display: flex;
        align-items: center;

        > h2 {
          margin-right: 8px;
        }
      }
    }
  }

  &--collaboration {
    .info--social-name {
      display: flex;
      align-items: center;
      gap: 6px;
      padding: 2px 8px 2px 3px;
      border-radius: 16px;
      width: fit-content;
      margin-top: 16px;

      > svg {
        width: 16px;
        height: 16px;
      }

      > span {
        text-transform: capitalize;
        font-size: 12px;
        font-weight: 500;
      }

      &.FACEBOOK {
        background-color: $primary-50;

        > span {
          color: $blue-700;
        }
      }

      &.INSTAGRAM {
        background-color: $rose-50;
      }
    }
  }

  &--rating {
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &--description {
    margin-top: 8px;
    font-size: 14px;
    color: $gray-700;
    line-height: 20px;
  }

  &--divider {
    width: 100%;
    height: 1px;
    background-color: #e8f0f8;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &--see-all {
    margin: auto;
    color: $primary-800;
    font-size: 12px;
    text-decoration: none;
    font-weight: 500;
  }
}
