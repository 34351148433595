@import 'src/global/styles/palette';

.avatar-group--wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .avatar-item {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid $white;
    background-size: cover;
    background-position: center;

    &:not(:first-child) {
      margin-left: -10px;
    }
  }
}
