@import 'src/global/styles/palette';

.log-in-page--content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  background-color: $white;

  .content--log-in-form-container {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 0 10px;
  }
}
