@import 'src/global/styles/palette';

.header-dashboard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 17px 25px;
  margin-bottom: 35px;
  position: sticky;
  background-color: #f4f5f7;
  top: 0;
  left: 0;
  z-index: 250;

  .header-dashboard--form {
    width: 100%;
    max-width: 360px;

    .from--search-container {
      position: relative;

      .input-field--wrapper {
        margin-bottom: 0;
      }
    }
  }

  .header-dashboard--form-input .input {
    height: 44px;
  }

  .header-dashboard--right-side {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .mr-25 {
      margin-right: 25px;
    }

    .vidgets-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .avatar-link {
      text-decoration: none;
    }
  }
}
