@import 'src/global/styles/palette';

.upload-field--wrapper {
  width: 100%;
  margin-bottom: 15px;

  .upload--wrapper {
    width: 100%;
    background-color: $white;
    position: relative;
    border-radius: 8px;

    .upload-field--label {
      width: 100%;
      padding: 15px;
      border: 1px solid $gray-200;
      border-radius: 8px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      .label--icon {
        margin-bottom: 15px;
      }

      .label--text {
        font-size: 14px;
        line-height: 20px;
        color: $gray-500;

        span {
          color: $primary-700;
          font-weight: 500;
        }
      }
    }

    .input {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  .upload-field--hint {
    width: 100%;
    margin-top: 5px;
    color: $gray-500;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }

  .upload-field--error-block-wrapper {
    width: 100%;

    &.error-block-animation-enter {
      opacity: 0;
    }

    &.error-block-animation-enter-active {
      opacity: 1;
      transition: opacity 500ms;
    }

    &.error-block-animation-exit {
      opacity: 1;
    }

    &.error-block-animation-exit-active {
      opacity: 0;
      transition: opacity 500ms;
    }

    .upload-error-block {
      width: 100%;
      margin-top: 5px;

      .upload-validation-message {
        color: $error-500;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
