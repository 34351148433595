@import 'src/global/styles/palette';

.chat {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 1;
  bottom: 0;
  right: 25px;
  width: 244px;
  padding: 12px 16px;
  gap: 24px;
  background: $white;
  border: 1px solid $gray-100;
  border-radius: 8px 8px 0 0;

  &-head {
    display: flex;
    width: 100%;
    gap: 8px;
    align-items: center;
  }

  &-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.43;
    color: $black;

    &-count {
      font-weight: 500;
      font-size: 12px;
      line-height: 1.33;
      color: $error-700;
      padding: 2px 8px;
      background-color: $error-50;
      border-radius: 16px;

      &-open {
        background-color: $gray-100;
        color: $gray-700;
      }
    }
  }

  &-open-icon {
    margin-left: auto;
  }

  &-no-new-messages {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.43;
    color: $gray-600;
  }
}
