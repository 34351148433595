@import 'src/global/styles/palette';

.progressLoader {
  width: calc(100% + 180px);
  height: calc(100vh - 195px);
  margin: 0 0 0 -180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  &__title {
    color: $gray-700;
  }

  &__bar {
    width: 300px;
  }

  &.loader-appearing-animation-enter {
    opacity: 0;
  }

  &.loader-appearing-animation-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }

  &.loader-appearing-animation-exit {
    opacity: 1;
  }

  &.loader-appearing-animation-exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }
}
