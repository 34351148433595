@import 'src/global/styles/palette';
//.main-container--wrapper .page--wrapper .page--content-wrapper .page--content{
//  padding-left: 25px;
//}
.dashboardPage {
  .chartsWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
  .chartContainer {
    width: 90%;
    margin: auto;
    min-height: 300px;
    canvas {
      width: 100%;
    }
  }
}
