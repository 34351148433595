@import 'src/global/styles/palette';

.user--socials {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .social {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:not(:last-child) {
      margin-right: 10px;
    }

    .social-icon {
      margin-right: 10px;
      width: 15px;
    }

    .social-value {
      font-size: 14px;
      line-height: 20px;
      color: $black;
    }
  }
}
