@import 'src/global/styles/palette';

.messageForm {
  display: flex;
  width: 100%;

  &-reply--wrapper {
    position: absolute;
    display: flex;
    bottom: 36px;
    left: -1px;
    height: 68px;
    background-color: $white;
    border-top: 1px solid $gray-300;
    border-left: 1px solid $gray-300;
    border-right: 1px solid $gray-300;
    border-radius: 8px 8px 0 0;
    width: calc(100% + 2px);
    padding: 15px;
  }

  &-reply {
    max-width: calc(100% - 50px);
    margin-left: 10px;
  }

  &-reply--file {
    display: flex;
    align-items: center;
  }

  &-reply--icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    background-color: $primary-100;
    border: 4px solid $primary-50;
    margin-right: 15px;
  }

  &-reply--owner {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.43;
    color: $gray-700;
  }

  &-reply--text {
    font-size: 14px;
    line-height: 1.43;
    color: $gray-500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-reply--close {
    position: absolute;
    right: 15px;
    background: none;
    border: none;
    cursor: pointer;
  }

  &-input--wrapper {
    position: relative;
    display: flex;
    height: 44px;
    align-items: center;
    padding: 10px 14px;
    background-color: $white;
    border: 1px solid $gray-300;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    min-width: calc(100% - 59px);

    .form-input {
      border: none;
      background: none;
      font-size: 16px;
      line-height: 1.5;
      color: $gray-700;
      min-width: calc(100% - 60px);
    }

    .input-btn {
      border: none;
      background: none;
      cursor: pointer;
      margin-left: 10px;
      display: flex;
      justify-content: center;
      align-content: center;

      &-file {
        display: none;
      }

      &:hover {
        cursor: pointer;

        path {
          stroke: $indigo-700;
        }
      }
    }
  }

  .form--submit-btn {
    margin-left: 15px;
    padding: 12px;
    cursor: pointer;

    &:disabled {
      background-color: #6941c6;
      opacity: 0.6;
    }
  }
}

.picker--container {
  position: absolute;
  bottom: 180px;
  right: 85px;
}

.messageFilesList {
  margin: 10px 0 0 0;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 5px 15px;
    padding: 0 60px 0 0;
  }

  &__listItem {
    display: flex;
    align-items: center;
  }

  &__deleteIcon {
    margin: 2px 0 0 7px;
    opacity: 0;
    cursor: pointer;
    transition: opacity 0.2s ease;
  }

  &__listItem:hover &__deleteIcon {
    opacity: 1;
  }

  &__fileIcon {
    margin: 0 3px 0 0;
  }

  &__fileText {
    color: $gray-500;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.43;
  }
}
