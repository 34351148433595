@import 'src/global/styles/palette';

.feedback-add-new {
  &--wrapper {
    width: 100%;
    background-color: $white;
    margin-bottom: 24px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid $gray-100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &--title {
    margin-bottom: 16px;
  }

  &.info-modal {
    div.info-modal-body {
      align-items: start;

      h4.info-modal--title {
        text-align: start;
      }

      p.info-modal--description {
        text-align: start;
        margin-bottom: 16px;
      }
    }

    div.info-modal--footer {
      margin-top: 16px;
    }
  }

  .modal {
    &--collaboration {
      width: 100%;

      p {
        color: $gray-700;
        font-weight: 500;
        line-height: 20px;
        font-size: 16px;
        text-align: start;
        margin-bottom: 8px;
      }

      .modal--collaboration-radio {
        margin-bottom: 32px;
        display: flex;
        align-items: center;
        gap: 24px;

        label input {
          margin-right: 8px;
        }
      }

      .modal--collaboration-select {
        margin-bottom: 32px;

        .modal--select {
          .collaboration-select {
            &__control {
              border-radius: 8px;

              &--is-focused {
                border-color: $primary-700;
                box-shadow: 0 0 0 1px $primary-700;
              }
            }

            &__single-value {
              display: flex;
              align-items: center;
              gap: 8px;

              .selected-option--icon {
                height: 24px;
              }
            }

            &__indicator-separator {
              display: none;
            }

            &__option {
              display: flex;
              align-items: center;
              gap: 8px;

              &--is-focused {
                background-color: $primary-700;

                span {
                  color: $white;
                }
              }

              &--is-selected {
                background-color: $primary-700;
              }

              .option--icon {
                height: 24px;
              }
            }
          }
        }
      }
    }

    &--ratings {
      margin-bottom: 32px;
      width: 100%;

      > p {
        text-align: start;
        color: $gray-700;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
      }

      .feedback-stats--categories p {
        width: auto;
      }

      .feedback-stats--rating p {
        width: 25px;
      }

      .state-danger {
        color: $error-600;
      }
    }

    &--description {
      width: 100%;

      p {
        text-align: start;
        color: $gray-700;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 8px;
      }

      textarea[name='feedbackDesription'] {
        height: 80px;
        width: 100%;
        resize: none;
        border-radius: 8px;
        border: 1px solid $gray-300;
        padding: 10px 14px;
        color: $gray-500;
        line-height: 24px;
        font-size: 16px;

        &.state-danger {
          border: 1px solid $error-600;
        }
      }
    }
  }
}
