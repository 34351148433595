@import 'src/global/styles/palette';

.search-empty-state--wrapper {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;

  .empty-state-icon {
    margin-bottom: 15px;
  }

  .empty-state--title {
    font-size: 18px;
    line-height: 28px;
    color: $gray-900;
    font-weight: 500;
    margin-bottom: 5px;
    max-width: 320px;
    text-align: center;
  }

  .empty-state--description {
    font-size: 14px;
    line-height: 20px;
    color: $gray-500;
    font-weight: 500;
    max-width: 320px;
    text-align: center;
  }
}
