@import 'src/global/styles/palette';

.search-result-container {
  width: 100%;
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  background-color: $white;
  border: 1px solid $gray-100;
  box-shadow: 0 8px 8px -4px #1018280a, 0 20px 24px -4px #1018281a;
  padding: 15px;
  border-radius: 8px;

  &.search-result-animation-enter {
    opacity: 0;
  }

  &.search-result-animation-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }

  &.search-result-animation-exit {
    opacity: 1;
  }

  &.search-result-animation-exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }

  .search-result--content {
    width: 100%;
    padding: 0 0 15px;

    .loader-box {
      width: 100%;
      height: auto;
      margin: 0;
      background-color: transparent;

      .circle-border {
        width: 50px;
        height: 50px;
        padding: 1px;

        .circle-core {
          background-color: $white;
        }
      }
    }

    .user--row {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-decoration: none;

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      .user--avatar {
        margin-right: 10px;
      }

      .user--info {
        .user--name {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 14px;
          line-height: 20px;
          color: $gray-900;
          font-weight: 500;
          margin-bottom: 5px;

          .user-verified-icon {
            margin-left: 10px;
          }
        }

        .user--position {
          font-size: 14px;
          line-height: 20px;
          color: $gray-500;
        }

        .user--socials {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .social {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            &:not(:last-child) {
              margin-right: 10px;
            }

            .social-icon {
              margin-right: 10px;
              width: 15px;
            }

            .social-value {
              font-size: 14px;
              line-height: 20px;
              color: $black;
            }
          }
        }
      }
    }

    .search--empty {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .search--empty--icon {
        margin-right: 10px;
      }

      .empty-message {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: flex-start;

        .message--title {
          font-size: 14px;
          line-height: 20px;
          color: $gray-900;
          font-weight: 500;
        }

        .message--description {
          font-size: 12px;
          line-height: 16px;
          color: $gray-500;
        }
      }
    }
  }

  .search-result--footer {
    padding: 15px 0 0;
    border-top: 1px solid #e8f0f8;
  }
}
