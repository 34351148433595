@import 'src/global/styles/palette';

.text-editor--wrapper {
  width: 100%;
  max-width: 100%;

  .text-editor--label {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: $gray-700;
    margin-bottom: 5px;
    display: block;
  }

  .text-editor--content {
    border: 1px solid $gray-100;
    background-color: $white;
    padding: 10px 15px;
    border-radius: 8px;

    .text-editor {
      overflow-wrap: anywhere !important;
      min-height: 125px !important;

      ul,
      ol {
        padding-left: 20px;
      }
    }
  }

  .text-editor--length-control {
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: $gray-500;
    margin-top: 5px;
  }

  .text-editor--toolbar {
    margin-top: 15px;
    padding: 10px;

    .edit-control-btn {
      border: none;
      background-color: transparent;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 25px;
      }

      &.active {
        svg {
          path {
            fill: $primary-700;
          }
        }
      }
    }
  }
}
