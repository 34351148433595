@import 'src/global/styles/palette';

.switch-btn {
  display: inline-block;
  margin: 0;
  position: relative;
  margin-bottom: 15px;
  font-size: 14px;
  color: $white;
  font-weight: 500;
  width: 100%;

  .switch-btn-label {
    margin-bottom: 5px;
    color: $gray-700;
    font-weight: 500;
    display: block;
    line-height: 20px;
  }

  .switch-btn-inner {
    margin: 0;
    height: 44px;
    background: $gray-50;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    display: block;
    font-size: 14px;
    color: $white;
    font-weight: 500;
    width: 100%;
    cursor: pointer;
  }

  .switch-btn-inner::before {
    content: attr(data-on);
    position: absolute;
    height: 28px;
    font-size: 14px;
    font-weight: 500;
    top: 7px;
    right: 0;
    width: 50%;
    color: $gray-400;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .switch-btn-inner::after {
    content: attr(data-off);
    width: 50%;
    height: 36px;
    background: $primary-600;
    border-radius: 8px;
    position: absolute;
    left: 4px;
    top: 4px;
    transition: all 0.3s ease;
    box-shadow: 0 0 6px -2px #111;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & input[type='checkbox'] {
    cursor: pointer;
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: 1;
    margin: 0;
  }

  & input[type='checkbox']:checked + .switch-btn-inner::after {
    content: attr(data-on);
    left: calc(50% - 4px);
    right: 0;
  }

  & input[type='checkbox']:checked + .switch-btn-inner::before {
    content: attr(data-off);
    right: auto;
  }
}
