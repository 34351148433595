@import 'src/global/styles/palette';

.alert-container {
  width: fit-content;
  max-width: 350px;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &.alert-success {
    background-color: $success-100;
  }

  &.alert-error {
    background-color: $error-100;
  }

  &.alert-warning {
    background-color: $orange-100;
  }

  .alert--icon-container {
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .alert-message {
    display: block;
    font-size: 16px;
    line-height: 24px;
    color: $gray-700;
  }
}
