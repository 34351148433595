.additional-page--form {
  .form--steps-container {
    width: 100%;

    .form--section {
      width: 100%;

      .btn-tertiary {
        margin-top: 15px;
      }

      .form--footer {
        .link {
          border: none;
          background-color: transparent;
          cursor: pointer;
        }
      }
    }

    &.fade-animation-enter .form--section {
      opacity: 0;
      transform: translateX(-100%);
    }

    &.fade-animation-enter-active .form--section {
      opacity: 1;
      transform: translateX(0%);
    }

    &.fade-animation-exit .form--section {
      opacity: 1;
      transform: translateX(0%);
    }

    &.fade-animation-exit-active .form--section {
      opacity: 0;
      transform: translateX(100%);
    }

    &.fade-animation-enter-active .form--section,
    &.fade-animation-exit-active .form--section {
      transition: opacity 500ms, transform 500ms;
    }
  }

  .btn-primary {
    margin-top: 30px;
  }
}
