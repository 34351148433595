@import 'src/global/styles/palette';

.influencer-search-page--wrapper {
  width: 100%;

  .influencer-search-page--filters-title {
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 15px;
    padding: 0 25px;
  }

  .influencer-search-page--filters-wrapper {
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    padding: 0 25px;

    .filters-form {
      display: flex;
      justify-content: flex-start;
      flex: 0 1 calc(100% - 200px);
      width: 100%;
      max-width: calc(100% - 200px);

      .social-select {
        flex: 0 1 165px;
        width: 100%;
        max-width: 165px;

        .select-field--wrapper {
          margin-bottom: 0;

          .select--wrapper {
            .select__control {
              min-height: 40px;
            }
          }
        }
      }

      .search-input {
        flex: 0 1 calc(100% - 165px);
        width: 100%;
        max-width: calc(100% - 165px);
        padding: 0 15px;

        .input-field--wrapper {
          margin-bottom: 0;

          .input {
            padding-top: 7px;
            padding-bottom: 7px;
          }
        }
      }
    }

    .btn {
      flex: 0 1 200px;
      width: 100%;
      max-width: 200px;
    }
  }

  .influencer-search-page--active-filters-wrapper {
    width: 100%;
    margin-bottom: 65px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 25px;

    .active-filter--item {
      padding: 2px 8px;
      background-color: $gray-100;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;

      .item--name {
        font-size: 12px;
        line-height: 16px;
        color: $gray-700;
        font-weight: 500;
        display: block;
        margin-right: 5px;
        white-space: nowrap;
      }
    }
  }

  .influencer-search-result--wrapper {
    width: 100%;

    .table {
      .table--header-row {
        .table--header-cell {
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(7),
          &:nth-child(8) {
            width: 9%;
            min-width: 75px;
          }

          &:nth-child(1) {
            width: 5%;
            min-width: 50px;
          }

          &:nth-child(6),
          &:nth-child(2) {
            width: 25%;
            min-width: 200px;
          }
        }
      }
    }
  }
}
