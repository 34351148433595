@import 'src/global/styles/palette';

.addAccount {
  margin-left: 0;
  text-align: center;
  background-color: $gray-25;
  min-height: 100%;
  width: 100%;
  border-radius: 15px;
  padding: 20px;
  .text {
    p {
      margin-top: 10px;
    }
  }
  .accountList {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 600px;
    margin: auto;
    .line {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin-top: 20px;
      h2 {
        text-align: center;
        width: 100%;
      }
      button {
        max-width: 200px;
      }
    }
  }
}
