@import 'src/global/styles/palette';

.search-page--wrapper {
  width: 100%;

  .search-page--companies-results,
  .search-page--influencers-results {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 15px;

    .influencers--table {
      .table--header-row {
        .table--header-cell {
          font-size: 12px;
          line-height: 16px;
          color: $gray-500;
          font-weight: 500;
          padding-bottom: 30px;
        }
      }

      .table--row {
        &:not(:last-child) {
          .table--cell {
            padding-bottom: 30px;
          }
        }

        .table--cell {
          width: 250px;

          .user-info {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .user-avatar {
              margin-right: 10px;
            }

            .user-details {
              .details-name {
                font-size: 14px;
                line-height: 20px;
                color: $gray-900;
                font-weight: 500;
              }

              .details-info {
                font-size: 14px;
                line-height: 20px;
                color: $gray-500;
              }
            }
          }
        }
      }
    }
  }

  .influencer-btn {
    margin-bottom: 25px;
  }
}
