@import 'src/global/styles/palette';

.chat-page--wrapper {
  width: 100%;
  height: 100%;
  margin-bottom: -80px;

  .chat-page--heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.33;
    color: $gray-700;
    margin-bottom: 15px;
  }

  .page-content--wrapper {
    display: flex;
    min-height: calc(100% - 80px);
  }

  .chat-container {
    display: flex;
    flex-direction: column;
    width: 65%;
    max-height: calc(100vh - 280px);

    .messages--wrapper {
      display: flex;
      flex-direction: column;
      background-color: $white;
      border-radius: 8px;
      margin-bottom: 15px;
      padding: 15px;
      height: calc(100% - 61px);
    }

    .chat-input--wrapper {
      margin: 0 auto;
      height: 45px;
    }
  }
}
