.main-container--wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  .page--wrapper {
    width: calc(100% - 280px);
    min-height: 100%;

    .page--content-wrapper {
      width: 100%;
      height: calc(100% - 111px);
      min-height: calc(100% - 111px);

      .page--content {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        height: 100%;
        min-height: 100%;
        padding-left: 25px;
        padding-right: 25px;
      }
    }
  }
}
