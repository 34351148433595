@import 'src/global/styles/palette';

.avatar--wrapper {
  .avatar {
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary-50;

    &-xs {
      width: 25px;
      height: 25px;

      .avatar--icon {
        width: 16px;
        height: 16px;
      }
    }

    &-sm {
      width: 35px;
      height: 35px;

      .avatar--icon {
        width: 20px;
        height: 20px;
      }
    }

    &-md {
      width: 40px;
      height: 40px;

      .avatar--icon {
        width: 24px;
        height: 24px;
      }
    }

    &-lg {
      width: 50px;
      height: 50px;

      .avatar--icon {
        width: 28px;
        height: 28px;
      }
    }

    &-xl {
      width: 55px;
      height: 55px;

      .avatar--icon {
        width: 32px;
        height: 32px;
      }
    }

    &-xxl {
      width: 65px;
      height: 65px;

      .avatar--icon {
        width: 32px;
        height: 32px;
      }
    }

    &-xxxl {
      width: 105px;
      height: 105px;

      .avatar--icon {
        width: 50px;
        height: 50px;
      }
    }

    &:focus {
      border: 4px solid $primary-100;
    }

    .avatar--img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
