@import 'src/global/styles/palette';

.checkbox-wrapper {
  display: block;
  position: relative;
  width: 100%;
  min-width: 20px;
  min-height: 20px;
  cursor: pointer;

  .checkbox__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 20px;
    height: 20px;
  }

  .checkbox__box {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid $gray-300;
    transition: 0.3s;
  }

  & .checkbox__input ~ .checkbox__box {
    &::after {
      content: '';
      display: none;
      width: 13px;
      height: 10px;
      background-image: url('../../global/images/icons/checkbox-tick.svg');
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  & .checkbox__input:hover ~ .checkbox__box {
    border: 1px solid $primary-600;
  }

  & .checkbox__input:focus ~ .checkbox__box {
    box-shadow: 0 0 0 4px #f4ebff;
  }

  & .checkbox__input:checked ~ .checkbox__box {
    border: 1px solid $primary-600;

    &::after {
      display: block;
    }
  }

  & .checkbox__input:disabled ~ .checkbox__box {
    border: 1px solid $gray-200;
    background-color: $gray-100;

    &::after {
      background-image: url('../../global/images/icons/checkbox-tick-disabled.svg');
    }
  }

  .checkbox__label {
    font-size: 14px;
    line-height: 20px;
    color: $gray-700;
    font-weight: 500;
    padding-left: 30px;
    margin-bottom: 15px;
  }
}
