@import 'src/global/styles/palette';

.feedbacks-page {
  &--wrapper {
    width: 100%;

    div.content {
      &--main {
        padding-right: 24px;
        width: 475px;
      }

      &--additional {
        width: 270px;
      }
    }
  }

  &--link-back {
    color: $black;
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  &--header {
    margin-bottom: 16px;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header {
      &--title {
        font-weight: 600;
        color: $gray-700;
      }

      &--select {
        .sort-select {
          &__control {
            background-color: transparent;
            border: 0;

            &--is-focused {
              box-shadow: none;
            }
          }

          &__indicator-separator {
            display: none;
          }

          &__option {
            cursor: pointer;

            &--is-focused {
              background-color: $primary-700;
              color: $white;
            }

            &--is-selected {
              background-color: $primary-700;
            }
          }
        }
      }
    }
  }

  &--posts {
    display: flex;
    flex-direction: column;
  }

  &--404 {
    > p {
      width: 100%;
      text-align: center;
    }
  }
}
