@import '../../../../../../global/styles/palette';

.chats-list--item {
  background-color: $white;
  border-radius: 8px;
  padding: 16px;
  display: block;
  max-width: 800px;

  &-active {
    background-color: $primary-50;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .item--user-info-wrapper {
    display: flex;
    justify-content: space-between;

    .item--message-date {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.43;
      color: $gray-500;
      margin-top: 13px;
    }
  }

  .item--message-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    font-size: 14px;
    line-height: 1.43;

    .item--message-text {
      font-weight: 400;
      color: $gray-600;
      margin-right: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .item--message-count {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      color: $gray-700;
      min-width: 35px;
      height: 24px;
      background-color: $gray-100;
      border-radius: 16px;
    }
  }
}
