@import 'src/global/styles/palette';

.no-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 61px);

  &--icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: $primary-100;
    border: 8px solid $primary-50;
    margin-bottom: 15px;
  }

  .no-message--title {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.56;
    color: $gray-900;
    margin-bottom: 5px;
  }

  .no-message--subtitle {
    text-align: center;
    max-width: 310px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.43;
    color: $gray-500;
  }
}
