@import 'src/global/styles/palette';

.app-sidebar {
  width: 100%;
  max-width: 280px;
  padding: 15px 0;
  background-color: $blue-gray-900;
  height: 100%;
  min-height: 100vh;
  max-height: 100vh;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 250;

  .sidebar--header {
    padding: 15px 15px 20px 25px;
  }

  .sidebar--routes {
    width: 100%;
    max-height: calc(100vh - 138px - 97px);
    overflow: auto;
    padding: 15px;
    border-bottom: 1px solid $blue-gray-200;
    border-top: 1px solid $blue-gray-200;

    .sidebar--route {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      .route--content {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .route--icon {
          margin-right: 10px;
        }

        .route--name {
          font-size: 16px;
          line-height: 20px;
          color: $blue-gray-100;
          font-weight: 500;
        }
      }

      .route--label {
        font-size: 14px;
        line-height: 20px;
        color: $blue-gray-800;
        padding: 2px 10px;
        background-color: $blue-gray-100;
        border-radius: 16px;
        font-weight: 500;
      }

      &.active {
        background-color: $primary-800;
        border-radius: 6px;
      }
    }
  }

  .sidebar--footer {
    padding: 25px 25px 0;

    .footer--links-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      max-width: 200px;

      .footer--link {
        font-size: 12px;
        line-height: 16px;
        color: $blue-gray-200;
        margin-right: 10px;
        margin-bottom: 2px;
        text-decoration: none;
      }
    }

    .footer--download-link {
      margin-bottom: 2px;
      font-size: 12px;
      line-height: 18px;
      color: $white;
      font-weight: 700;
      text-decoration: none;
      display: block;
    }

    .footer--copyright {
      font-size: 12px;
      line-height: 16px;
      color: $blue-gray-200;
    }
  }

  &.influencer-sidebar {
    background-color: $white;

    .sidebar--routes {
      border-bottom: 1px solid $gray-200;
      border-top: 1px solid $gray-200;

      .sidebar--route {
        .route--content {
          .route--name {
            color: $gray-700;
          }
        }

        .route--icon {
          path {
            stroke: $gray-700;
          }
        }

        .route--label {
          background-color: $primary-50;
          color: $primary-700;
        }

        &.active {
          background-color: $primary-50;

          .route--content {
            .route--name {
              color: $primary-700;
            }
          }

          .route--icon {
            path {
              stroke: $primary-700;
            }
          }
        }
      }

      .sidebar--language-select {
        .language-select__control {
          .language-select__value-container {
            .language-select__single-value {
              .option--text {
                color: $gray-700;
              }
            }
          }

          .language-select__indicators {
            .language-select__indicator {
              outline: none;
            }
          }
        }
      }
    }

    .sidebar--footer {
      .footer--links-row {
        .footer--link {
          color: $gray-600;
        }
      }

      .footer--download-link {
        color: $primary-700;
      }

      .footer--copyright {
        color: $gray-600;
      }
    }
  }
}
