@import 'src/global/styles/palette';

.sign-up-page--content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  background-color: $white;

  .content--sign-up-form-container {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}
