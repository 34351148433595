$white: #fff;
$black: #000;

$gray-25: #fcfcfd;
$gray-50: #f9fafb;
$gray-100: #f2f4f7;
$gray-200: #e4e7ec;
$gray-300: #d0d5dd;
$gray-400: #98a2b3;
$gray-500: #667085;
$gray-600: #475467;
$gray-700: #344054;
$gray-800: #1d2939;
$gray-900: #101828;

$primary-25: #fcfaff;
$primary-50: #f6f5ff;
$primary-100: #f4ebff;
$primary-200: #e9d7fe;
$primary-300: #d6bbfb;
$primary-500: #9e77ed;
$primary-600: #7f56d9;
$primary-700: #6941c6;
$primary-800: #53389e;
$primary-900: #42307d;

$error-50: #fef3f2;
$error-100: #fee4e2;
$error-200: #fecdca;
$error-300: #fda29b;
$error-500: #f04438;
$error-600: #d92d20;
$error-700: #b42318;
$error-800: #912018;

$blue-50: #eff8ff;
$blue-700: #175cd3;

$blue-gray-100: #eaecf5;
$blue-gray-200: #c8cce5;
$blue-gray-500: #4e5ba6;
$blue-gray-700: #363f72;
$blue-gray-800: #293056;
$blue-gray-900: #101323;

$blue-light-500: #0ba5ec;

$indigo-700: #3538cd;
$indigo-800: #2d31a6;

$purple-800: #4a1fb8;

$rose-50: #fdf2fa;
$rose-700: #c11574;

$success-100: #d1fadf;
$success-500: #12b76a;

$orange-100: #ffead5;
