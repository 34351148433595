@import 'src/global/styles/palette';

.chats-list--wrapper {
  min-width: 32.5%;
  margin-right: 25px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 61px);
  max-height: calc(100vh - 280px);

  .chats-searchbar {
    height: 45px;
    margin-bottom: 15px;
  }

  .chats-list {
    list-style: none;
    overflow-y: auto;
  }
}
