@import '../../global/styles/palette';

.feed-post--wrapper {
  padding: 25px;
  background-color: $white;
  margin: 15px 0 25px;
  border-radius: 8px;

  .feed-post--header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 15px;

    .feed-post--user-info-row {
      width: 100%;
      display: flex;
    }

    .header--user {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .user--avatar {
        margin-right: 10px;
      }

      .user--info {
        .info--name {
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          color: $gray-700;

          .verified-icon {
            margin-left: 10px;
          }
        }

        .info--email {
          font-size: 14px;
          line-height: 20px;
          color: $gray-500;
        }
      }
    }

    .header--control-btn {
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;

      &:hover {
        cursor: pointer;

        path {
          stroke: $indigo-700;
        }
      }
    }

    .published--wrapper {
      width: 100%;
      padding: 0 0 0 52px;

      .published--date {
        color: $gray-400;
        font-size: 10px;
        line-height: 13px;
        font-weight: 600;
      }
    }
  }

  .feed-post--body {
    width: 100%;

    .body--text {
      margin: 0 0 15px;
      width: 100%;
      font-size: 14px;
      line-height: 20px;
      color: $gray-500;
      position: relative;
      white-space: pre-wrap;

      .hidden-text {
        z-index: 20;
        width: 100%;
        background: linear-gradient(1deg, $white 0%, rgba(255, 255, 255, 0.72) 100%);
        height: 60px;
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: flex-end;

        .link-btn {
          margin-bottom: 10px;
        }
      }
    }

    .images--wrapper {
      width: 100%;

      .single-image--wrapper {
        width: 100%;

        .img,
        .video {
          width: 100%;
          max-height: 350px;
          object-fit: cover;
        }
      }

      .duo-images--wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .image--wrapper {
          flex: 0 1 50%;
          width: 100%;
          max-width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          &:first-child {
            padding-right: 5px;
          }

          &:last-child {
            padding-left: 5px;
          }

          .img,
          .video {
            width: 100%;
            max-height: 350px;
            object-fit: cover;
          }
        }
      }

      .trio-image--wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .left-image {
          flex: 0 1 50%;
          width: 100%;
          max-width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-right: 5px;

          .img,
          .video {
            width: 100%;
            max-height: 350px;
            object-fit: cover;
          }
        }

        .right-images {
          flex: 0 1 50%;
          width: 100%;
          max-width: 50%;
          padding-left: 5px;
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;

          .img,
          .video {
            width: 100%;
            max-height: 175px;
            object-fit: cover;

            &:first-child {
              padding-bottom: 10px;
            }
          }
        }
      }

      .squad-image--wrapper {
        width: 100%;

        .imgs--row {
          width: 100%;
          display: flex;
          justify-content: space-between;

          &:first-child {
            padding-bottom: 5px;
          }

          &:last-child {
            padding-top: 5px;
          }

          .img--wrapper {
            flex: 0 1 50%;
            width: 100%;
            max-width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            &:first-child {
              padding-right: 5px;
            }

            &:last-child {
              padding-left: 5px;
            }

            .img,
            .video {
              width: 100%;
              max-height: 175px;
              object-fit: cover;
            }
          }
        }
      }

      .post-image--wrapper {
        width: 100%;

        .upper--row {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding-bottom: 5px;

          .img--wrapper {
            flex: 0 1 50%;
            width: 100%;
            max-width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            &:last-child {
              padding-left: 5px;
            }

            &:first-child {
              padding-right: 5px;
            }

            .img,
            .video {
              width: 100%;
              max-height: 175px;
              object-fit: cover;
            }
          }
        }

        .down--row {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding-top: 5px;

          .img--wrapper {
            flex: 0 1 33.33%;
            width: 100%;
            max-width: 33.33%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-right: 5px;

            .img,
            .video {
              width: 100%;
              max-height: 175px;
              object-fit: cover;
            }
          }

          .see-more {
            flex: 0 1 33.33%;
            width: 100%;
            max-width: 33.33%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            background-size: cover;
            background-repeat: no-repeat;
            margin-left: 5px;

            .more--text {
              width: 100%;
              height: 100%;
              background-color: rgba(16, 24, 40, 0.2);
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 28px;
              line-height: 32px;
              color: $primary-600;
              font-weight: 800;
            }
          }
        }
      }
    }
  }

  .feed-post--comments {
    .comments--loader-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 30px 0 14px;
    }

    .comment {
      margin-top: 16px;
      display: flex;

      &--body {
        margin-left: 12px;
      }

      &--user-name {
        color: $gray-700;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        span {
          margin-left: 8px;
          color: $gray-400;
          font-size: 10px;
          line-height: 13px;
          font-weight: 600;
        }
      }

      &--message {
        margin-top: 6px;
        background-color: $gray-100;
        padding: 10px 14px;
        width: fit-content;
        box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
        border-radius: 0 8px 8px;
      }
    }

    .post-editor {
      margin-top: 16px;
    }
  }

  .feed-post--footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .footer--interact-btn {
      border: none;
      background: none;

      &:hover {
        cursor: pointer;

        path {
          stroke: $indigo-700;
        }
      }

      &:disabled,
      &:disabled:hover {
        cursor: default;
        opacity: 0.4;

        path {
          stroke: #667085;
        }
      }
    }

    .footer--btn-block {
      display: flex;
      align-items: center;
    }

    .interact-btn--count {
      display: inline-block;
      margin: 0 20px 0 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.43;
      color: $gray-700;
    }
  }
}
