@import 'src/global/styles/palette';

.notification-item--wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  > div:first-child {
    display: flex;
  }

  .notification-item--avatar {
    margin-right: 8px;
  }

  .notification-item--info {
    display: flex;
    align-items: center;
    gap: 4px;

    .info {
      &--title {
        color: $gray-700;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }

      &--company {
        color: $gray-500;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  .notification-item--description {
    color: $gray-500;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;
  }

  .notification-item--footer {
    display: flex;
    align-items: center;

    .btn-gray {
      color: $gray-500;
      margin-right: 12px;
    }

    button {
      width: auto;
    }
  }

  .notification-item--control-btn {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    &:hover {
      cursor: pointer;

      path {
        stroke: $primary-700;
      }
    }
  }
}
