@import 'src/global/styles/palette';

.chat--heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid #c8cce5;

  .heading--btn-wrapper {
    display: flex;

    .heading--feedback-btn {
      width: 165px;
      margin-right: 15px;
    }

    .heading--control-btn {
      width: 35px;
      padding: 8px;

      &:hover {
        path {
          stroke: $indigo-700;
        }
      }
    }
  }
}

.chat-messages--wrapper {
  overflow-y: auto;
  overflow-x: hidden;
}
