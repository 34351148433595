@import 'src/global/styles/palette';

.checkbox-field--wrapper {
  width: 100%;

  .checkbox--wrapper {
    width: 100%;

    .checkbox--label {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;

      .checkbox--input {
        appearance: none;
        display: none;
        margin: 0;

        &:focus ~ .label--checkmark {
          &::before {
            border: 1px solid $primary-300;
            box-shadow: 0 0 0 4px $primary-100;
          }
        }

        &:checked ~ .label--checkmark {
          &::before {
            border: 1px solid $primary-600;
          }

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 3px;
            transform: translateY(calc(-50% + 1px));
            width: 12px;
            height: 12px;
            background-image: url('../../global/images/icons/checkbox-tick.svg');
            background-size: contain;
            background-repeat: no-repeat;
          }
        }

        &:checked:disabled ~ .label--checkmark {
          &::after {
            background-image: url('../../global/images/icons/checkbox-tick-disabled.svg');
          }
        }

        &:disabled ~ .label--checkmark {
          &::before {
            border: 1px solid $gray-100;
            background-color: $gray-100;
          }
        }

        &:disabled ~ .label--content {
          color: $gray-300;
        }
      }

      .label--checkmark {
        margin-right: 10px;

        &::before {
          content: '';
          display: block;
          width: 16px;
          height: 16px;
          border-radius: 4px;
          border: 1px solid $gray-300;
          transition: 300ms ease-in;
        }
      }

      .label--content {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: $gray-700;
      }

      &:hover {
        .label--checkmark {
          &::before {
            border: 1px solid $primary-600;
          }
        }
      }
    }

    .checkbox-field--hint {
      width: 100%;
      font-size: 14px;
      line-height: 20px;
      color: $gray-500;
      padding-left: 28px;
    }
  }
}
