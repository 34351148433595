@import 'src/global/styles/palette';

.find-social-page--form {
  .form--steps-container {
    width: 100%;

    &.fade-animation-enter .social-form {
      opacity: 0;
      transform: translateX(-100%);
    }

    &.fade-animation-enter-active .social-form {
      opacity: 1;
      transform: translateX(0%);
    }

    &.fade-animation-exit .social-form {
      opacity: 1;
      transform: translateX(0%);
    }

    &.fade-animation-exit-active .social-form {
      opacity: 0;
      transform: translateX(100%);
    }

    &.fade-animation-enter-active .social-form,
    &.fade-animation-exit-active .social-form {
      transition: opacity 500ms, transform 500ms;
    }

    .social-form {
      width: 100%;
      max-width: 360px;
      display: flex;
      flex-direction: column;

      .btn {
        margin-bottom: 15px;
      }

      .social-form-subtitle {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 25px;

        .highlighted-span {
          font-weight: 600;
          color: $black;
        }
      }

      .confirm-form-list {
        margin-bottom: 25px;

        .confirm-form-list--item {
          .item--text {
            font-size: 16px;
            line-height: 20px;

            .highlighted-span {
              font-weight: 600;
              color: $black;
            }
          }

          .find-social-form--code-input {
            margin-top: 15px;
            cursor: pointer;

            .code-input-field--wrapper {
              .code-input--wrapper {
                .code-input-box {
                  & > input {
                    cursor: pointer !important;
                  }
                }
              }
            }
          }
        }
      }

      .open-social-link {
        font-size: 16px;
        line-height: 20px;
        color: $blue-700;

        &:hover {
          text-decoration: underline;
        }
      }

      .find-social-form--qr {
        align-self: center;
        margin: 25px 0;
      }
    }
  }
}
