.verified-page--wrapper {
  height: 100%;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;

  .verified-page--form {
    width: 100%;
    max-width: 360px;
    display: flex;
    flex-direction: column;

    .btn {
      margin-bottom: 15px;
    }
  }
}
