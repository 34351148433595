@import 'src/global/styles/palette';

.code-input-field--wrapper {
  width: 100%;
  margin-bottom: 30px;

  .code-input--wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    .code-input-label {
      font-size: 14px;
      line-height: 20px;
      color: $gray-700;
      font-weight: 500;
    }

    .code-input-box {
      input {
        width: 80px;
        height: 80px;
        padding: 10px;
        font-size: 48px;
        line-height: 60px;
        border: 1px solid $gray-300;
        border-radius: 8px;
        box-shadow: 0 1px 2px 0 #1018280d;
        text-align: center;
        color: $gray-700;
        transition: 300ms ease-in;

        &:not(:last-child) {
          margin-right: 10px;
        }

        &:focus {
          border: 1px solid $primary-300;
          box-shadow: 0 0 0 4px $primary-100, 0 1px 2px 0 #1018280d;
        }

        &::placeholder,
        &:disabled {
          color: $gray-300;
        }
      }
    }

    .code-input-field--hint {
      margin-top: 5px;
      color: $gray-500;
      font-size: 14px;
      line-height: 20px;
    }

    .code-input-field--error-block-wrapper {
      width: 100%;

      &.error-block-animation-enter {
        opacity: 0;
      }

      &.error-block-animation-enter-active {
        opacity: 1;
        transition: opacity 500ms;
      }

      &.error-block-animation-exit {
        opacity: 1;
      }

      &.error-block-animation-exit-active {
        opacity: 0;
        transition: opacity 500ms;
      }

      .code-input-error-block {
        width: 100%;
        margin-top: 5px;

        .input-validation-message {
          color: $error-500;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}
