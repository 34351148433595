@import 'src/global/styles/palette';

.profile-social-metrics--wrapper {
  background-color: $gray-25;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
  .stats {
    p {
      margin-top: 10px;
      text-transform: capitalize;
    }
  }
}
