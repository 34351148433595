@import 'src/global/styles/palette';

.profile-settings-page--wrapper {
  width: 100%;

  .app-pages--content {
    .content--main {
      .profile-settings-page--form {
        width: 100%;
        max-width: 450px;
        margin: 0 auto 40px auto;

        .profile-settings-page--form-title {
          font-size: 20px;
          line-height: 30px;
          font-weight: 600;
          color: $gray-700;
          margin-bottom: 25px;

          &.m-top {
            margin-top: 40px;
          }
        }

        .avatar-input--wrapper {
          width: 100%;
          display: flex;
          align-items: flex-start;
          margin: 40px 0 25px;

          .avatar-upload-input {
            margin-left: 25px;
            width: 100%;
          }
        }

        .bio-input--wrapper {
          margin-bottom: 35px;
        }

        .profile-settings-page--form-footer {
          width: 100%;
          display: flex;
          justify-content: flex-end;

          .btn {
            width: 145px;

            &:first-child {
              margin-right: 15px;
            }
          }

          &.w-100 {
            .btn {
              width: 100%;
              margin-top: 30px;
            }
          }
        }
      }

      .profile-settings-page--footer {
        width: 100%;
        max-width: 450px;
        margin: 0 auto 40px auto;

        .btn-primary {
          margin-bottom: 25px;
        }
      }
    }
  }
}
