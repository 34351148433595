@import 'src/global/styles/palette';

.footer--wrapper {
  width: 100%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .footer--copyright {
    color: $gray-500;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .footer--mail-link {
    display: flex;
    align-items: center;
    text-decoration: none;

    .mail-link--icon {
      margin-right: 10px;
    }
  }
}
