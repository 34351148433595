@import 'src/global/styles/palette';

.heading--wrapper {
  width: 100%;
  margin-bottom: 30px;

  .heading--title {
    color: $gray-700;
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .heading--subtitle {
    color: $gray-500;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;

    .subtitle--subtext {
      font-weight: 500;
      display: block;
    }
  }

  p.heading--subtitle {
    width: 100%;

    &:not(:last-child) {
      margin: 0 0 12px 0;
    }
  }

  &.align-center {
    display: flex;
    flex-flow: column;
    align-items: center;

    .heading--title {
      text-align: center;
    }

    .heading--subtitle {
      text-align: center;
    }
  }
}
