@import '../../../../../../global/styles/palette';

.chatModal-container {
  position: absolute;
  width: 150px;
  height: auto;
  padding: 10px;
  background: $white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  opacity: 1;
  transition: opacity 0.5s linear;
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  z-index: 2;
}

.chatModal-item {
  display: flex;
  align-items: center;
  padding: 10px;
  height: 40px;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.43;
  color: $gray-700;
  border-radius: 6px;
  border: none;
  background-color: unset;

  &:hover {
    color: $gray-700;
    background: $primary-50;
    transition: 300ms ease-in;
  }
}
