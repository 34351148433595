@import 'src/global/styles/palette';

.select--container {
  border: none;
  color: $gray-500;
  cursor: pointer;
  width: 61px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  option {
    border: none;
    white-space: normal;
    background: transparent;
  }
}
