@import '../../../../../../global/styles/palette';

@keyframes on-focus-animation {
  0% {
    background: transparent;
  }

  50% {
    background-color: $primary-100;
  }

  100% {
    background: transparent;
  }
}

.message-item--wrapper {
  display: flex;
  width: 60%;

  .info {
    width: 100%;

    &--name-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
    }

    &--name {
      font-size: 14px;
      line-height: 1.43;
      color: $gray-700;
      font-weight: 500;
    }

    &--date {
      font-size: 12px;
      line-height: 1.33;
      color: $gray-500;
      font-weight: 500;
    }
  }

  .item--message {
    padding: 10px 20px 10px 15px;
    box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 0 8px 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.43;
    color: $gray-700;
    overflow-wrap: break-word;
    word-wrap: break-word;
    height: auto;
    position: relative;
  }

  .item--link {
    text-decoration: none;
    margin-bottom: 10px;
  }

  .item--file {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .file--icon {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 35px;
      min-height: 35px;
      border-radius: 50%;
      background-color: $primary-100;
      border: 3px solid $primary-50;
      margin-right: 15px;
    }

    .file--info {
      display: flex;
      flex-direction: column;

      .file--name {
        display: block;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.43;
        color: $gray-700;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
          overflow-wrap: break-word;
          word-wrap: break-word;
          height: auto;
        }
      }

      .file--size {
        font-size: 14px;
        line-height: 1.43;
        color: $gray-500;
      }
    }
  }

  .message {
    background: $gray-100;
    border-radius: 0 8px 8px;
  }

  .message--mine {
    background: $white;
    border-radius: 8px 0 8px 8px;
  }
}

.message-item--mine {
  margin-right: 20px;
  margin-left: auto;
  display: block;
}

.message-item {
  display: block;
}

.reply {
  display: flex;
  align-items: center;
  border-left: 1px solid #c8cce5;
  margin-bottom: 5px;
  width: calc(100%);
  cursor: pointer;

  &-text-block {
    width: calc(100% - 20px);
    display: block;
  }

  &-owner {
    font-size: 12px;
    line-height: 1.43;
    margin: 0 0 5px 5px;
  }

  &-file--wrapper {
    display: flex;
    align-items: center;
  }

  &--icon {
    margin: 0 5px 5px;
  }

  &-file--icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: $primary-100;
    border: 2px solid $primary-50;
    margin: 0 0 5px 5px;
  }

  &--text {
    display: inline-block;
    max-width: calc(100%);
    font-size: 12px;
    line-height: 1.43;
    color: $gray-500;
    margin: 0 0 5px 5px;
    padding-right: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.message-item--container {
  display: flex;
  margin: 20px 0 50px 5px;
  padding: 5px 0 5px 5px;
}

.focus {
  animation-duration: 1.5s;
  animation-name: on-focus-animation;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

.item--read-icon {
  position: absolute;
  right: 5px;
  bottom: 0;
}

.message-item--avatar {
  margin-right: 15px;
}
