@import '../../../../../../global/styles/palette';

.sidebar--language-select {
  margin-bottom: 10px;

  .language-select__control {
    background-color: transparent;
    border: none;

    &.language-select__control--is-focused {
      box-shadow: 0 0 0 1px $primary-800;
    }

    .language-select__value-container {
      .language-select__single-value {
        font-size: 16px;
        line-height: 20px;
        color: $blue-gray-100;
        font-weight: 500;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .selected-option--icon {
          width: 25px;
          height: 25px;
          margin-right: 10px;

          .icon--img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .language-select__indicators {
      .language-select__indicator {
        padding: 7px;
      }

      .language-select__indicator-separator {
        display: none;
      }
    }
  }

  .language-select__menu {
    background-color: $blue-gray-900;

    .language-select__menu-list {
      .language-select__option {
        font-size: 16px;
        line-height: 20px;
        color: $blue-gray-100;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &.language-select__option--is-focused {
          background-color: $primary-800;
        }

        .option--icon {
          width: 25px;
          height: 25px;
          margin-right: 10px;

          .icon--img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
