@import 'src/global/styles/palette';

.post-editor {
  width: 100%;
  height: fit-content;
  border-radius: 10px;
  border: 1px solid $gray-200;
  padding: 15px 15px 15px 65px;
  position: relative;
  background-color: $white;

  .avatar--wrapper {
    position: absolute;
    top: 12px;
    left: 15px;
  }

  &-top {
    display: flex;
    align-items: center;
  }

  .post-editor-input {
    .MuiInputBase-root {
      font-family: 'Montserrat', sans-serif;
      padding: 10px 4px 4px 4px;

      > fieldset {
        border: none;
      }
    }
  }

  #PostFeedEditorTextArea {
    font-size: 16px;
    line-height: 24px;
    color: $gray-700;
  }

  .gallery-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;

    &-item {
      list-style-type: none;
      margin: 5px;
      position: relative;
      border: none;
      outline: none;
      background-color: transparent;
    }

    &-media {
      width: 175px;
      height: 120px;
      object-fit: cover;
      border-radius: 5px;
      box-shadow: 0 1px 2px 0 #1018280d;
    }

    &-media:hover {
      box-shadow: 0 0 0 4px $primary-100, 0 1px 2px 0 #1018280d;
      background: rgba(0, 0, 0, 0.5);
    }

    &-item::after {
      opacity: 0;
      transition: 0.3s;
      content: '';
      font-size: 50px;
      position: absolute;
      top: 0;
      left: 0;
      width: 175px;
      height: 120px;
      background: rgba(0, 0, 0, 0.5);
      background-image: url('../../global/images/x_mark.png');
      background-position: center;
      background-size: 40px;
      background-repeat: no-repeat;
      border-radius: 5px;
      cursor: pointer;
    }

    &-item:hover::after {
      opacity: 1;
    }
  }

  &-bottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;

    &-media {
      display: flex;

      .media-button {
        cursor: pointer;
        background: transparent;
        border: none;

        .media-button-input {
          display: none;
        }
      }

      .media-button:not(:last-child) {
        margin-right: 15px;
      }
    }

    .btn {
      justify-self: end;
      display: block;
      max-width: 65px;
    }
  }
}
