@import 'src/global/styles/palette';

.feedback-single {
  &--wrapper {
    width: 100%;
    background-color: $white;
    margin-bottom: 16px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid $gray-100;
  }

  &--header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .header {
      &--user {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        .user--avatar {
          margin-right: 10px;
        }

        .user--info {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .info--name {
            font-size: 18px;
            line-height: 28px;
            font-weight: 500;
            color: $gray-700;

            .verified-icon {
              margin-left: 10px;
            }
          }

          .info--email {
            font-size: 14px;
            line-height: 20px;
            color: $gray-500;
          }

          .info--date {
            color: $gray-500;
            font-size: 14px;
            text-align: end;
            margin-bottom: 8px;
          }

          .info--social-name {
            display: flex;
            align-items: center;
            gap: 6px;
            padding: 2px 8px 2px 3px;
            border-radius: 16px;

            > svg {
              width: 16px;
              height: 16px;
            }

            > span {
              text-transform: capitalize;
              font-size: 12px;
              font-weight: 500;
            }

            &.FACEBOOK {
              background-color: $primary-50;

              > span {
                color: $blue-700;
              }
            }

            &.INSTAGRAM {
              background-color: $rose-50;
            }
          }
        }

        .user--info-name {
          display: flex;
          align-items: center;

          > h2 {
            margin-right: 8px;
          }
        }
      }
    }
  }

  &--rating {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &--description {
    color: $gray-700;
    line-height: 20px;
    font-size: 14px;
    margin-top: 8px;
  }
}
