@import 'palette';

*,
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  font-family: Montserrat, sans-serif;
}

body {
  &.no-scroll {
    max-width: 100%;
    max-height: 100vh;
    overflow: hidden;
  }
}

#bup-app {
  width: 100%;
  background-color: #f4f5f7;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;

  .application-wrapper__containers-wrapper {
    width: 100%;
    max-width: 1920px;
    position: relative;

    .application-alerts-container {
      width: 100%;
      max-width: 350px;
      position: fixed;
      top: 25px;
      right: 25px;
      z-index: 1000;
      display: flex;
      flex-flow: column;
      align-items: flex-end;
      justify-content: flex-start;
    }

    .containers-wrapper__container-wrapper {
      width: 100%;
    }
  }
}

.btn {
  width: 100%;
  border-radius: 8px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: 300ms ease-in;
  box-shadow: 0 1px 2px 0 #1018280d;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  & .btn--icon {
    margin-right: 10px;
  }

  &-xs {
    max-height: 30px;
    font-size: 12px;
    line-height: 16px;
    padding: 6px 12px;
  }

  &-sm {
    max-height: 35px;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 16px;
  }

  &-md {
    max-height: 40px;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 14px;
  }

  &-lg {
    font-size: 16px;
    line-height: 20px;
    padding: 10px 20px;
  }

  &-primary {
    color: $white;
    background-color: $primary-700;

    &:hover {
      background-color: $primary-900;
    }

    &:focus {
      box-shadow: 0 0 0 4px $primary-100, 0 1px 2px 0 #1018280d;
    }

    &:disabled {
      background-color: $primary-100;
    }

    &-error {
      background-color: $error-600;

      &:hover {
        background-color: $error-700;
      }

      &:focus {
        box-shadow: 0 0 0 4px $error-100, 0 1px 2px 0 #1018280d;
      }

      &:disabled {
        background-color: $error-200;
      }
    }
  }

  &-secondary {
    color: $primary-800;
    border: 1px solid $primary-100;
    background-color: $primary-100;

    &:hover {
      background-color: $primary-200;
      border: 1px solid $primary-200;
    }

    &:focus {
      box-shadow: 0 0 0 4px $primary-100, 0 1px 2px 0 #1018280d;
    }

    &:disabled {
      background-color: $primary-25;
      border: 1px solid $primary-25;
    }

    &-gray {
      color: $gray-800;
      border: 1px solid $gray-300;
      background-color: $white;

      &:hover {
        background-color: $gray-50;
        border: 1px solid $gray-300;
      }

      &:focus {
        box-shadow: 0 0 0 4px $gray-100, 0 1px 2px 0 #1018280d;
      }

      &:disabled {
        color: $gray-200;
        border: 1px solid $gray-200;
      }
    }
  }

  &-tertiary {
    background-color: transparent;
    border: 1px solid $gray-300;
    color: $gray-700;

    &:hover {
      background-color: $gray-50;
      color: $gray-800;
    }

    &:focus {
      box-shadow: 0 0 0 4px $gray-100, 0 1px 2px 0 #1018280d;
    }

    &:disabled {
      color: $gray-300;
      border: 1px solid $gray-200;
    }

    &.active {
      background-color: $primary-600;
      color: $white;
      border: 1px solid $primary-600;
    }
  }

  &-link {
    background-color: transparent;
    color: $primary-700;
    box-shadow: none;

    &:hover {
      color: $primary-800;
    }

    &:disabled {
      color: $gray-300;
    }
  }

  &-link-error {
    background-color: transparent;
    color: $error-700;
    box-shadow: none;

    &:hover {
      color: $error-800;
    }

    &:disabled {
      color: $error-300;
    }
  }
}

.link {
  font-weight: 500;
  text-decoration: none;
  transition: 500ms ease-in;
  display: flex;
  justify-content: center;
  align-items: center;

  .link--icon {
    margin-right: 10px;
  }

  &-md {
    font-size: 14px;
    line-height: 20px;
  }

  &-gray {
    color: $gray-500;

    &:hover {
      color: $gray-600;
    }

    &:disabled {
      color: $gray-300;
    }
  }

  &-color {
    color: $primary-700;

    &:hover {
      color: $primary-800;
    }

    &:disabled {
      color: $gray-300;
    }
  }

  &-btn {
    border: none;
    background: none;
    cursor: pointer;
  }
}

.additional-page--wrapper {
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  background-color: $white;

  .additional-page--form {
    width: 100%;
    max-width: 390px;
    padding: 0 15px;
    display: flex;
    flex-direction: column;

    .form--sub-action {
      margin-top: 25px;
      display: flex;
      justify-content: center;

      .sub-action--text {
        color: $gray-500;
        font-size: 14px;
        line-height: 20px;
      }

      .sub-action--btn {
        border: none;
        background-color: transparent;
        margin-left: 5px;
        cursor: pointer;
        color: $gray-600;
      }
    }

    .form--footer {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 25px;
    }
  }
}

.app-pages--content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;

  .content--main {
    width: calc(100% - 245px);
    padding-right: 25px;
  }

  .content--additional {
    width: 245px;
    position: fixed;
    top: 101px;
    right: 25px;
    max-height: 90vh;
    overflow: auto;
    padding-bottom: 100px;
  }
}

.table {
  width: 100%;

  .table--header-row {
    .table--header-cell {
      font-size: 12px;
      line-height: 16px;
      color: $gray-500;
      font-weight: 500;
      padding: 15px 5px;

      &.table--header-cell__align-left {
        text-align: left;
      }

      .table--header-cell__with-tooltip {
        display: flex;
        align-items: center;

        span {
          margin-right: 5px;
        }
      }
    }
  }

  .table--row {
    .table--cell {
      padding: 15px 5px;

      .user-info--wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .user-avatar {
          margin-right: 10px;
        }

        .info--main {
          .user-name--wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .user-name {
              margin-right: 10px;
              font-size: 14px;
              line-height: 20px;
              color: $gray-900;
              font-weight: 500;
            }
          }

          .user-info {
            font-size: 14px;
            line-height: 20px;
            color: $gray-500;
          }
        }
      }
    }
  }
}
