@import 'src/global/styles/palette';

.user-info--wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;

  .info {
    width: 100%;
    flex: 0 1 calc(100% - 40px);
    max-width: calc(100% - 40px);

    &-sm {
      margin-left: 10px;
    }

    &-md {
      margin-left: 12px;
    }

    &-lg {
      margin-left: 12px;
    }

    &-xl {
      margin-left: 16px;
    }

    &-xxl {
      margin-left: 16px;
    }

    &--name-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    &--name {
      font-weight: 500;
      color: $gray-700;
      cursor: pointer;
      text-decoration: none;
      width: 100%;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &-sm {
        font-size: 14px;
        line-height: 1.43;
      }

      &-md {
        font-size: 14px;
        line-height: 1.43;
      }

      &-lg {
        font-size: 16px;
        line-height: 1.25;
      }

      &-xl {
        font-size: 18px;
        line-height: 1.56;
      }

      &-xxl {
        font-size: 18px;
        line-height: 1.56;
      }
    }

    &--subtitle {
      font-weight: 400;
      color: $gray-500;
      width: 100%;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &-sm {
        font-size: 12px;
        line-height: 1.33;
      }

      &-md {
        font-size: 14px;
        line-height: 1.43;
      }

      &-lg {
        font-size: 16px;
        line-height: 1.5;
      }

      &-xl {
        font-size: 16px;
        line-height: 1.5;
      }

      &-xxl {
        font-size: 14px;
        line-height: 1.43;
      }
    }
  }
}
