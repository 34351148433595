@import 'src/global/styles/palette';

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

.loader-box {
  width: calc(100% + 200px);
  height: calc(100vh - 195px);
  margin-left: -200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f5f7;
  z-index: 1000;

  &.loader-appearing-animation-enter {
    opacity: 0;
  }

  &.loader-appearing-animation-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }

  &.loader-appearing-animation-exit {
    opacity: 1;
  }

  &.loader-appearing-animation-exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }

  .circle-border {
    width: 150px;
    height: 150px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: $primary-800;
    background: linear-gradient(0deg, rgba(83, 56, 158, 0.1) 25%, $primary-800 100%);
    animation: spin 0.8s linear 0s infinite;

    .circle-core {
      width: 100%;
      height: 100%;
      background-color: #f4f5f7;
      border-radius: 50%;
    }
  }
}
