@import './src/global/styles/palette';

.industries-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.justify-left {
    justify-content: flex-start;
  }

  &.justify-right {
    justify-content: flex-end;
  }

  .industry-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    border-radius: 16px;

    &:not(:last-child) {
      margin-right: 5px;
    }

    .industry-name {
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
    }

    &.first-item {
      background-color: $primary-50;

      .industry-name {
        color: $primary-700;
      }
    }

    &.second-item {
      background-color: $blue-50;

      .industry-name {
        color: $blue-700;
      }
    }

    &.last-element {
      background-color: $gray-100;

      .industry-name {
        color: $gray-700;
      }
    }
  }
}
