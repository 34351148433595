@import 'src/global/styles/palette';

.sign-up-form {
  width: 100%;
  max-width: 360px;

  .btn {
    &:not(:last-child) {
      margin-bottom: 15px;
    }

    .btn--icon {
      margin-right: 10px;
    }
  }

  .sign-up-form--already-have,
  .sign-up-form--terms-and-conditions {
    color: $gray-500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;

    &.sign-up-form--already-have {
      margin: 30px 0;
    }

    .link {
      margin-left: 5px;
    }
  }
}
